import recovery from "@/store/office/recovery/recovery";
import stats from "@/store/office/stats";
import report from "@/store/office/report";
import userReport from "@/store/office/userReport";
import backups from "@/store/office/backups";

const defaultState = () => ({})

export default {
  namespaced: true,

  actions: {},

  modules: {
    recovery: recovery,
    stats: stats,
    report,
    userReport,
    backups
  }
}
