import {GET} from "@/services/gateway";

const getStats = () => {
  return GET('dashboard/stats/microsoft')
}

const getFinishedBackup = () => {
  return GET('dashboard/microsoft/ready')
}

export {
  getStats,
  getFinishedBackup
}
