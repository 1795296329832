<template>
  <img
      class="i-generate-image"
      :src="generateImage"
      :alt="alt"
  >
</template>

<script>
export default {
  name: "IGenerateImage",
  props: {
    image: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: 'image',
    }
  },
  computed: {
    generateImage() {
      return require(`../assets/${this.image}`)
    },
  },
}
</script>

<style lang="scss">
@import "IBackupStatus";
</style>
