const SEAT_STATUS = {
  'active': 1,
  'paused': 2,
  'unprotected': 3,
  'archived': 4,
  'unprotecting': 5,
}

const SEAT_STATUS_ACTIONS = {
  'active': 'protect',
  'paused': 'pause',
  'unprotected': 'unprotect',
}

const getEnumLabel = value => {
  return Object.keys(SEAT_STATUS).find(key => SEAT_STATUS[key] === value)
}

const getAlternativesForStatus = status => {
  switch (status) {
    case 1:
      return [2, 3];
    case 2:
      return [1, 3];
    case 3:
      return [1];
    default:
      return [];
  }
};

const getDataSetLabel = label => {
  if (label === 0) {
    return "Contact"
  } else if (label === 1) {
    return "Calendar"
  } else if (label === 2) {
    return "Mail"
  } else if (label === 3) {
    return "Drive"
  } else if (label === 5) {
    return "List"
  }
  return "/"
}

const getStatus = label => {
  if (label === 'PROGRESS') {
    return "In progress"
  } else if (label === 'COMPLETED') {
    return "Completed"
  } else if (label === 'FAILED') {
    return "Failed"
  }
  else if (label === 'PENDING') {
    return "Pending"
  }
  return "/"
}

const getTypeName = value => {
  if (value === 1) {
    return "Backup"
  } else if (value === 2) {
    return "Restore"
  } else if (value === 3) {
    return "Export"
  }
  return "/"
}

const getReportingModuleName = value => {
  if (value === 1) {
    return "Drive"
  } else if (value === 2) {
    return "Mail"
  } else if (value === 3) {
    return "Calendar"
  } else if (value === 4) {
    return "Contacts"
  } else if (value === 5) {
    return "Exchange"
  } else if (value === 6) {
    return "Team"
  } else if (value === 7) {
    return "SharePoint"
  }
  return "/"
}

const getBackupJobModule = (value, provider) => {
  if (value === 0) {
    return "Contacts"
  } else if (value === 1) {
    return "Calendar"
  } else if (value === 2) {
    return "Gmail"
  } else if (value === 3 && provider === 1) {
    return "OneDrive"
  } else if (value === 3 && provider === 2) {
    return "Drive"
  } else if (value === 4) {
    return "Exchange"
  } else if (value === 5) {
    return "Team"
  } else if (value === 6) {
    return "SharePoint"
  } else if (value === 7) {
    return "List"
  }
  return "/"
}

export {
  SEAT_STATUS,
  SEAT_STATUS_ACTIONS,
  getAlternativesForStatus,
  getEnumLabel,
  getDataSetLabel,
  getStatus,
  getTypeName,
  getReportingModuleName,
  getBackupJobModule
}
