import {GET} from "@/services/gateway";

const getStats = () => {
  return GET('dashboard/stats/google')
}

const getFinishedBackup = () => {
  return GET('dashboard/google/ready')
}

export {
  getStats,
  getFinishedBackup
}
