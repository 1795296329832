import {getReport, listEventLogs, exportEventLogs} from "@/services/api/google/report";
import {defaultPagination} from "@/services/pagination";

const defaultState = () => ({
  systemEventReports: [],
  list: [],
  pagination: {
    ...defaultPagination(),
    sortBy: 'startTime',
  },
});

export default {
  namespaced: true,

  actions: {
    list({commit, state}, data) {
      return listEventLogs({
        ...data,
        pagination: state.pagination
      })
        .then(({result, pagination}) => {
          commit('set', result)
          commit('setPagination', pagination)
        })
    },
    export({commit, state}, data) {
      return exportEventLogs(data)
    },
    getReport({commit}, data) {
      return getReport(data)
        .then(report => {
          commit('setSystemEventReport', report);

          return report;
        })
    }
  },

  mutations: {
    set(state, eventLogs) {
      state.list = eventLogs;
    },
    setSystemEventReport(state, systemEventReports) {
      state.systemEventReports = systemEventReports
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    resetPagination(state) {
      state.pagination = {
        ...defaultPagination(),
        sortBy: 'startTime',
      };
    },
  },

  getters: {
    getBackupReports: (state) => () => {
      return state.systemEventReports.find(item => item.type === 1)
    }
  },

  state: defaultState(),

  modules: {},

}
