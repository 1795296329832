<template>
  <div class="i-switcher">
    <div v-if="label" class="field-label">
      {{ label }}
    </div>

    <div class="switcher-item-container" :class="{active: value}">
      <div
          class="switcher-item"
          @click="onSwitch(!value)"
      >
        <span class="switcher"></span>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ISwitcher",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onSwitch(value) {
      if (this.isLoading) {
        return false;
      }

      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
@import "ISwitcher";
</style>
