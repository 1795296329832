import Store from "@/store";
import {generateMicrosoftAuthLink} from "@/services/microsoft";

let ENVIRONMENT = 'staging';

if (process.env.VUE_APP_ENVIRONMENT) {
  ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT;
}

const getRoutes = store => {

  const checkAuth = (to, from, next) => {
    Store.dispatch("auth/getSelf")
      .then(user => {
        next();
      })
      .catch(() => {
        next({
          name: "SignInPage"
        });
      });
  }

  const checkNoAuth = (to, from, next) => {
    if (Store.state.auth.accessToken) {
      next({
        name: "HomeLayout"
      });
    } else {
      next();
    }
  };

  const checkNoTenant = (to, from, next) => {
    if (Store.state.auth.self.providerTenants && Store.state.auth.self.providerTenants.length) {
      if (Store.getters['auth/isMicrosoftProviderEnabled']) {
        next({
          name: "MicrosoftHomePage",
          replace: true
        });
      } else if (Store.getters['auth/isGoogleWorkspaceProviderEnabled']) {
        next({
          name: "GoogleHomePage"
        });
      } else {
        next();
      }

    } else {
      next();
    }
  };

  const singleSignOn = (routeName) => (to, from, next) => {
    Store.commit('auth/setAccessToken', to.params.token)
    next({
      name: routeName,
    });
  }

  const checkMicrosoftTenant = (to, from, next) => {
    if (Store.state.auth.self) {
      if (Store.getters['auth/isMicrosoftProviderEnabled']) {
        if (Store.state.auth.self?.providerTenants?.find(item => item?.providerTenantName === 'Microsoft')?.disableTime === null) {
          next()
        } else {
          next({
            name: 'AccountDisabled'
          })
        }
      } else {
        if (ENVIRONMENT === 'production') {
          window.location.href = generateMicrosoftAuthLink(Store.state.auth.self.id)
        } else {
          next({
            name: 'HomeLayout'
          })
        }
      }
    } else {
      next({
        name: 'HomeLayout'
      })
    }
  }

  const checkGoogleTenant = (to, from, next) => {
    if (Store.state.auth.self) {
      if (Store.getters['auth/isGoogleWorkspaceProviderEnabled']) {
        if (Store.state.auth.self?.providerTenants?.find(item => item?.providerTenantName === 'Google')?.disableTime === null) {
          next()
        } else {
          next({
            name: 'AccountDisabled'
          })
        }
      } else {
        next({
          name: 'GoogleWorkspaceLayout'
        })
      }
    } else {
      next({
        name: 'HomeLayout'
      })
    }
  }

  const logoutAndRedirect = (from, to, next) => {
    Store.dispatch('auth/signOut', false)
      .then(() => {
        // console.log('FROM', from.query)
        if (from.query.redirect_url) {
          window.location.href = from.query.redirect_url
        } else {
          if (Store.state.auth?.self?.platform === "360") {
            window.location.href = 'https://webapp.idrive360.com/api/v1/logout'
          } else {
            window.location.href = 'https://www.idrive.com/idriveent/login/logout'
          }
        }
        // next(true)

      })
  }

  const routes = [
    {
      path: '/auth',
      component: () => import('../layout/AuthLayout'),
      beforeEnter: checkNoAuth,
      children: [
        {
          path: 'sign-up',
          name: 'SignUpPage',
          component: () => import('../pages/auth/SignUpPage')
        },
        {
          path: 'sign-in',
          name: 'SignInPage',
          component: () => import('../pages/auth/SignInPage')
        },
        {
          path: 'forgot-password',
          name: "ForgotPasswordPage",
          component: () => import('../pages/auth/ForgotPasswordPage')
        },
      ]
    },
    {
      path: '/',
      component: () => import('../layout/MainLayout'),
      beforeEnter: checkAuth,
      children: [
        // MICROSOFT365 START
        {
          path: '/microsoft-365/register-error',
          name: 'MicrosoftRegisterErrorPage',
          component: () => import('../pages/auth/MicrosoftRegisterErrorPage')
        },
        {
          path: 'microsoft-365',
          component: () => import('../layout/office/AppLayout'),
          beforeEnter: checkMicrosoftTenant,
          children: [
            {
              path: '',
              component: () => import('../layout/PageLayout'),
              children: [
                {
                  path: 'welcome',
                  name: 'MicrosoftWelcomePage',
                  component: () => import('../layout/office/WelcomePage'),
                },
                {
                  path: 'home',
                  name: 'MicrosoftHomePage',
                  meta: {activeMenu: 'overview'},
                  component: () => import('../pages/office/HomePage'),
                },
                // {
                //   path: 'administrators',
                //   name: 'AdministratorsMicrosoftPage',
                //   meta: {activeMenu: 'administrators'},
                //   component: () => import('../pages/office/admin/AdministratorsPage'),
                // },
                {
                  path: 'reports',
                  name: 'ReportsOfficePage',
                  redirect: {name: 'SystemEventsPage'},
                  component: () => import('../pages/office/admin/ReportsPage'),
                  children: [
                    {
                      path: 'system-events',
                      name: 'SystemEventsPage',
                      meta: {activeMenu: 'administrators'},
                      component: () => import('../pages/office/admin/reporting/SystemEventsPage'),
                    },
                    {
                      path: 'user-actions',
                      name: 'UserActionsPage',
                      meta: {activeMenu: 'administrators'},
                      component: () => import('../pages/office/admin/reporting/UserActionsPage'),
                    },
                  ]
                },
              ],
            },
            {
              path: 'seat-management',
              component: () => import('../layout/PageLayout'),
              children: [
                {
                  path: 'users',
                  name: 'UsersPage',
                  meta: {activeMenu: 'seat-management'},
                  component: () => import('../pages/office/seat-management/UsersPage'),
                },
                {
                  path: 'sites',
                  name: 'SitesPage',
                  meta: {activeMenu: 'seat-management'},
                  component: () => import('../pages/office/seat-management/SitesPage'),
                },
                {
                  path: 'teams',
                  name: 'TeamsPage',
                  meta: {activeMenu: 'seat-management'},
                  component: () => import('../pages/office/seat-management/TeamsPage'),
                },

              ],

            },
            {
              path: 'recovery',
              component: () => import('../layout/PageLayout'),
              children: [
                {
                  path: 'onedrive',
                  name: 'OneDrivePage',
                  redirect: {name: 'OneDriveUsersPage'},

                  component: () => import('../layout/PageLayout'),
                  props: {collection: 'oneDrive'},
                  children: [
                    {
                      path: 'users',
                      name: 'OneDriveUsersPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/UsersPage'),
                    },
                    {
                      path: 'users/:userId/:folderId?',
                      name: 'RecoveryOneDriveUserDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/UserDetailsPage.vue'),
                      props: true,
                    },
                    {
                      path: 'search',
                      name: 'OneDriveSearchPage',
                      meta: {activeMenu: 'recovery'},
                      props: true,
                      component: () => import('../pages/common/SearchPage.vue'),
                    },
                    {
                      path: 'export',
                      name: 'OneDriveExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'OneDriveRestorePage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'OneDriveBackupPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/BackupPage'),
                    },
                  ]
                },
                {
                  path: 'exchange',
                  name: 'ExchangePage',

                  redirect: {name: 'ExchangeUsersPage'},
                  component: () => import('../layout/PageLayout'),
                  props: {collection: 'exchange'},

                  children: [
                    {
                      path: 'users',
                      name: 'ExchangeUsersPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/UsersPage'),
                    },
                    {
                      path: 'users/:userId/:dataSet(mail|contact|calendar)/:folderId?',
                      name: 'RecoveryExchangeUserDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/UserDetailsPage.vue'),
                      // component: () => import('../layout/office/recovery/exchange/UserDetailsLayout'),
                      props: true,
                    },
                    {
                      path: 'search',
                      name: 'ExchangeSearchPage',
                      meta: {activeMenu: 'recovery'},
                      props: true,
                      component: () => import('../pages/common/SearchPage.vue'),
                    },
                    {
                      path: 'export',
                      name: 'ExchangeExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'ExchangeRestorePage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'ExchangeBackupPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/BackupPage'),
                    },
                  ]
                },
                {
                  path: 'sharepoint',
                  name: 'SharePointPage',
                  redirect: {name: 'SharePointSitesPage'},
                  props: {collection: 'sharePoint'},
                  component: () => import('../layout/PageLayout'),
                  children: [
                    {
                      path: 'sites',
                      name: 'SharePointSitesPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/office/recovery/share-point/SitesPage'),
                    },
                    {
                      path: 'sites/:siteId/:dataSet(drive|list)/:folderId?',
                      name: 'RecoverySharePointSiteDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../layout/office/recovery/share-point/SiteDetailsLayout'),
                      props: true,
                    },
                    {
                      path: 'search',
                      name: 'SharePointSearchPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/SearchPage.vue'),
                    },
                    {
                      path: 'export',
                      name: 'SharePointExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'SharePointRestorePage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'SharePointBackupPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/BackupPage'),
                    },
                  ]
                },
                {
                  path: 'teams',
                  name: 'RTeamsPage',
                  redirect: {name: 'TeamsTeamsPage'},
                  component: () => import('../layout/PageLayout'),
                  props: {collection: 'team'},

                  children: [
                    {
                      path: 'teams',
                      name: 'TeamsTeamsPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/office/recovery/team/TeamsPage'),
                    },
                    {
                      path: 'teams/:teamId/:dataSet(drive)/:folderId?',
                      name: 'RecoveryTeamDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../layout/office/recovery/team/TeamDetailsLayout'),
                      props: true,
                    },
                    {
                      path: 'search',
                      name: 'TeamSearchPage',
                      meta: {activeMenu: 'recovery'},
                      props: true,
                      component: () => import('../pages/common/SearchPage.vue'),
                    },
                    {
                      path: 'export',
                      name: 'TeamExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'TeamRestorePage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'TeamBackupPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/BackupPage'),
                    },
                  ]
                },
              ]
            },
          ]
        },
        // MICROSOFT365 END
        {
          path: 'add-googleworkspace',
          name: 'GoogleWorkspaceLayout',
          component: () => import('../layout/google/GoogleWorkspaceLayout'),
          beforeEnter: checkNoTenant && !checkGoogleTenant
        },
        // GOOGLE START
        {
          path: 'google',
          component: () => import('../layout/google/AppLayout'),
          beforeEnter: checkGoogleTenant,
          children: [
            {
              path: '',
              component: () => import('../layout/PageLayout'),
              children: [
                {
                  path: 'welcome',
                  name: 'GoogleWelcomePage',
                  component: () => import('../layout/google/WelcomePage'),
                },
                {
                  path: 'home',
                  name: 'GoogleHomePage',
                  meta: {activeMenu: 'overview'},
                  component: () => import('../pages/google/HomePage'),
                },
                // {
                //   path: 'administrators',
                //   name: 'AdministratorsGooglePage',
                //   meta: {activeMenu: 'administrators'},
                //   component: () => import('../pages/office/admin/AdministratorsPage'),
                // },
                {
                  path: 'reports',
                  name: 'ReportsGooglePage',
                  redirect: {name: 'GoogleSystemEventsPage'},
                  component: () => import('../pages/google/admin/ReportsPage'),
                  children: [
                    {
                      path: 'system-events',
                      name: 'GoogleSystemEventsPage',
                      meta: {activeMenu: 'administrators'},
                      component: () => import('../pages/google/admin/reporting/SystemEventsPage'),
                    },
                    {
                      path: 'user-actions',
                      name: 'GoogleUserActionsPage',
                      meta: {activeMenu: 'administrators'},
                      component: () => import('../pages/google/admin/reporting/UserActionsPage'),
                    },
                  ]
                },
              ],
            },
            {
              path: 'seat-management',
              component: () => import('../layout/PageLayout'),
              children: [
                {
                  path: 'users',
                  name: 'GUsersPage',
                  meta: {activeMenu: 'seat-management'},
                  component: () => import('../pages/google/seat-management/UsersPage'),
                },
              ],
            },
            {
              path: 'recovery',
              component: () => import('../layout/PageLayout'),
              children: [

                {
                  path: 'drive',
                  name: 'DrivePage',
                  redirect: {name: 'DriveUsersPage'},
                  component: () => import('../layout/PageLayout'),
                  props: {collection: 'drive'},

                  children: [
                    {
                      path: 'users',
                      name: 'DriveUsersPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/UsersPage'),
                    },
                    {
                      path: 'search',
                      name: 'DriveSearchPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/SearchPage.vue'),
                    },

                    {
                      path: 'users/:userId/:folderId?',
                      name: 'RecoveryDriveUserDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/UserDetailsPage.vue'),
                      props: true,
                    },

                    {
                      path: 'exports',
                      name: 'DriveExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'DriveRestoresPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'DriveBackupsPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/BackupPage'),
                    },
                  ]
                },
                {
                  path: 'gmail',
                  name: 'GmailPage',
                  redirect: {name: 'GmailUsersPage'},
                  component: () => import('../layout/PageLayout'),
                  props: {collection: 'gmail'},
                  children: [
                    {
                      path: 'users',
                      name: 'GmailUsersPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/UsersPage'),
                    },
                    {
                      path: 'users/:userId/:folderId?',
                      name: 'RecoveryGmailUserDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/UserDetailsPage.vue'),
                      props: true,
                    },
                    {
                      path: 'search',
                      name: 'GmailSearchPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/SearchPage.vue'),
                    },
                    {
                      path: 'export',
                      name: 'GmailExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'GmailRestorePage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'GmailBackupPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/BackupPage'),
                    },
                  ]
                },
                {
                  path: 'contact',
                  name: 'ContactPage',
                  redirect: {name: 'ContactUsersPage'},
                  component: () => import('../layout/PageLayout'),
                  props: {collection: 'contact'},
                  children: [
                    {
                      path: 'users',
                      name: 'ContactUsersPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/UsersPage'),
                    },
                    {
                      path: 'users/:userId/:folderId?',
                      name: 'RecoveryContactUserDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/UserDetailsPage.vue'),
                      props: true,
                    },
                    {
                      path: 'search',
                      name: 'ContactSearchPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/SearchPage.vue'),
                    },
                    {
                      path: 'export',
                      name: 'ContactExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'ContactRestorePage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'ContactBackupPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/BackupPage'),
                    },
                  ]
                },
                {
                  path: 'calendar',
                  name: 'CalendarPage',
                  redirect: {name: 'CalendarUsersPage'},
                  component: () => import('../layout/PageLayout'),
                  props: {collection: 'calendar'},
                  children: [
                    {
                      path: 'users',
                      name: 'CalendarUsersPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/UsersPage'),
                    },
                    {
                      path: 'users/:userId/:folderId?',
                      name: 'RecoveryCalendarUserDetailsLayout',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/UserDetailsPage.vue'),
                      props: true,
                    },
                    {
                      path: 'search',
                      name: 'CalendarSearchPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/SearchPage.vue'),
                    },
                    {
                      path: 'export',
                      name: 'CalendarExportPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/ExportPage'),
                    },
                    {
                      path: 'restores',
                      name: 'CalendarRestorePage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/RestorePage'),
                    },
                    {
                      path: 'backups',
                      name: 'CalendarBackupPage',
                      meta: {activeMenu: 'recovery'},
                      component: () => import('../pages/common/BackupPage'),
                    },
                  ]
                },
              ]
            },
          ]
        },
        // GOOGLE END
        {
          path: '',
          name: 'HomeLayout',
          component: () => import('../layout/HomeLayout'),
        },
      ]
    },
    {
      path: '/account-disabled',
      name: 'AccountDisabled',
      component: () => import('../pages/auth/AccountDisabled'),
    },
    {
      path: '/sso/google/:token',
      beforeEnter: singleSignOn('GoogleHomePage'),
    },
    {
      path: '/sso/microsoft/:token',
      beforeEnter: singleSignOn('MicrosoftHomePage'),
    },
    {
      path: '/logout',
      name: 'LogoutPage',
      component: () => import('../pages/LogoutPage'),
    },
    {
      path: '/sign-in-test',
      name: 'SignInTestPage',
      component: () => import('../pages/auth/SignInTestPage')
    },
    {
      path: '/logout/redirect',
      name: 'LogoutAndRedirectPage',
      component: () => import('../pages/LogoutPage.vue'),
      beforeEnter: logoutAndRedirect,
    },
    // Always leave this as last one
    {
      path: '*',
      name: 'ErrorPage',
      component: () => import('../pages/ErrorPage'),
      beforeEnter: checkAuth,
    }
  ]

  return routes;
}

export {getRoutes};
