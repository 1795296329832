import Vue from "vue";
import {format} from "date-fns";
import {getAddressName, getLocationName, convertSize, getStatusLabel} from "@/services/tableDataFormater";

Vue.filter('FormatDateTime', function (dateTimeString) {
    try {
        if (dateTimeString) {
            return format(new Date(dateTimeString), 'MM-dd-yyyy hh:mm a');
        }
    } catch (e) {
        return dateTimeString
    }
});

Vue.filter('iFormatAddress', function (address) {
    try {
        if (address) {
            return getAddressName(address)
        }
    } catch (e) {
        return address
    }
});

Vue.filter('iFormatLocation', function (location, providerTenant) {
    try {
        if (location) {
            if (providerTenant === 'MICROSOFT') {
                return getLocationName(location)
            } else {
                return location.replace(/"/g, '')
            }
        }
    } catch (e) {
        return location
    }
});

Vue.filter('iConvertSize', function (size) {
    try {
        if (size) {
            return convertSize(size)
        }
    } catch (e) {
        return size
    }
});

Vue.filter('iConvertItemsSize', function (size) {
    try {
        if (size) {
            return convertSize(size)
        }
        return '0'
    } catch (e) {
        return size
    }
});

Vue.filter('iGetStatusLabel', function (value) {
    try {
        if (value) {
            return getStatusLabel(value)
        }
    } catch (e) {
        return location
    }
});
