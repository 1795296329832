<template>
  <div class="i-user-multi-picker">

    <i-multi-picker
        :label="label"
        :value="value"
        :fetch-options="fetchUsers"
        option-label="name"
        option-value="userId"
        @input="$val => $emit('input',$val)"
    />
  </div>
</template>

<script>
import {list as listMicrosoftUsers} from "@/services/api/seat-management/user";
import {list as listGoogleUsers} from "@/services/api/google/seat-management/user";
import IMultiPicker from "@/components/forms/IMultiPicker.vue";

export default {
  name: 'IUserMultiPicker',
  components: {IMultiPicker},
  props: {
    value: {},
    label: {
      type: String,
      default: 'Users'
    },
    placeholder: {
      type: String,
      default: 'Search all users'
    },
    providerTenant: {
      type: String,
      default: 'MICROSOFT'
    }
  },

  data() {
    return {
      selected: null,
      users: []
    }
  },

  methods: {
    fetchUsers({search}){
      let list = this.providerTenant === 'MICROSOFT' ? listMicrosoftUsers : listGoogleUsers

      return list({
        pagination: {
          pageNumber: 1,
          pageSize: 30,
          sortBy: 'name',
          sortDescending: false
        },
        search,
        filter: {
          "statuses": [
            1, 2, 4
          ]
        }
      })
          .then(({result}) => {
           return result
          })
    },
  },


}
</script>

<style lang="scss">
@import "IUserMultiPicker.scss";
</style>
