import {updateAutoAdd, list} from "@/services/api/seat-management/backupsettings";

const defaultState = () => ({
  autoAdd: {
    userSeats: false,
    siteSeats: false,
    teamSeats: false
  }
});

export default {
  namespaced: true,

  actions: {
    list({commit}) {
      return list()
        .then(({autoAdd}) => {
          commit('setAutoAdd', autoAdd)
        })
    },
    updateAutoAdd({commit}, data) {
      return updateAutoAdd(data)
        .then(({autoAdd}) => {
          commit('setAutoAdd', autoAdd)
        })
    }
  },

  mutations: {
    setAutoAdd(state, autoAdd) {
      state.autoAdd = autoAdd
    }
  },


  getters: {
    //seatType: usersSeat, sitesSeat, teamsSeat
    getAutoAddSettingByType: (state) => (seatType) => {
      return state.autoAdd && state.autoAdd[seatType];
    },
  },

  state: defaultState()
}
