import {POST, GET} from "@/services/gateway";

const register = ({firstName, lastName, businessEmail, CompanyName, CompanySize, JobFunction, JobLevel, country, postalCode, password, confirmPassword, region, AcceptTerms}) => {
  return POST('account/register',
    {firstName, lastName, businessEmail, CompanyName, CompanySize, JobFunction, JobLevel, country, postalCode, password, confirmPassword, region, AcceptTerms: true}
  )
}

const authenticate = ({email, password}) => {
  return POST('account/authenticate', {email, password})
}

const getSelf = () => {
  return GET('user/self')
}

const googleConnect = ({adminEmail, serviceAccount}) => {
  return POST('Tenant/google/connect', {adminEmail, serviceAccount})
}


export {
  register,
  authenticate,
  getSelf,
  googleConnect
}
