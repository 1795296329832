import {POST} from "@/services/gateway";

const getReport = (data) => {
  return POST('report/summary', data);
}

const getOtherEventsReport = (data) => {
  return POST('report/summary/other', data);
}

const listEventLogs = (data) => {
  return POST('report/events', data);
}

const exportEventLogs = (data) => {
  return POST('report/export', data);
}

export {
  getReport,
  getOtherEventsReport,
  listEventLogs,
  exportEventLogs
}
