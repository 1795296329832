import {GET, POST} from "@/services/gateway";

const listSites = ({pagination, search, filter}) => {
  return POST('seat/site/list', {pagination, search, filter});
}

const listSnapshots = ({siteId, date}) => {
  return POST('recovery/sites/snapshot/list', {siteId, date})
}

const createBackup = (siteId) => {
  return GET('recovery/sites/backup', {siteId})
}

const getSiteDetails = (siteId) => {
  return GET('recovery/sites/site', {siteId})
}


export {
  listSites,
  createBackup,
  getSiteDetails,
  listSnapshots,
}
