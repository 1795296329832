import drive from './data-set/drive'
import exports from "@/store/office/recovery/team/exports";
import restores from "@/store/office/recovery/team/restores";
import backups from "@/store/office/recovery/team/backups";
import {createBackup, getTeamDetails, listSnapshots, listTeams} from "@/services/api/office/recovery/team/team";
import {defaultPagination} from "@/services/pagination";

const defaultState = () => ({
  teams: [],
  teamMap: {},
  pagination: {
    ...defaultPagination(),
    sortBy: 'name',
    sortDescending: false
  },
  search: null
});

export default {
  namespaced: true,
  actions: {
    listTeams({commit, state},) {
      return listTeams({
        pagination: state.pagination,
        search: state.search,
        filter: {
          "statuses": [
            1, 2, 4
          ]
        }
      })
        .then(({result, pagination}) => {
          commit('setTeams', result)
          commit('setPagination', pagination)
        })
    },
    getTeamDetails({commit}, teamId) {
      return getTeamDetails(teamId)
        .then(team => {
          commit('setTeam', team);
          return team;
        })
    },
    createBackup({commit}, teamId) {
      return createBackup(teamId);
    },
    listSnapshots({commit}, {teamId, date}) {
      return listSnapshots({teamId, date})
    }

  },

  mutations: {
    setTeams(state, teams) {
      state.teams = teams;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    resetPagination(state, {keepSort}) {
      if (keepSort) {
        state.pagination = {
          ...defaultPagination(),
          sortBy: state.pagination.sortBy,
          sortDescending: state.pagination.sortDescending,
        }
      } else {
        state.pagination = {
          ...defaultPagination(),
          sortBy: 'name',
          sortDescending: false
        };
      }

    },
    setSearch(state, search) {
      state.search = search;
    },
    setTeam(state, team) {
      state.teamMap = {
        ...state.teamMap,
        [team.teamId]: team
      };
    },
    resetState(state) {
      Object.assign(state, defaultState())
    }
  },

  getters: {
    getTeamById: (state) => (id) => {
      return state.teamMap[id];
    },
  },

  state: defaultState(),

  modules: {
    drive,
    exports,
    restores,
    backups,
  },

}
