<template>
  <div class="i-backup-status-dropdown">
    <i-dropdown
      :label="label"
      v-model="value"
      :options="options"
      @input="onInput"
    />
  </div>
</template>

<script>
import IDropdown from "@/components/common/IDropdown";

export default {
  name: "IBackupStatusDropdown",
  components: {IDropdown},

  data() {
    return {
      options: [
        {label: "All", value: ""},
        {label: "Pending", value: 1},
        {label: "In progress", value: 2},
        {label: "Completed", value: 3},
        {label: "Failed", value: 4},
      ],
      value: {},
    }
  },

  props: {
    label: {
      type: String,
      default: 'Status',
    },
  },

  methods: {
    onInput(e) {
      this.$emit('input', e);
    },
  },
}
</script>

<style lang="scss">
@import "IBackupStatusDropdown";
</style>
