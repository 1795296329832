import {
  getStats,
  getFinishedBackup
} from "@/services/api/office/stats";

const defaultState = () => ({
  stats: {},
  finishedBackup: false,
});

export default {
  namespaced: true,

  actions: {
    getStats({commit}) {
      return getStats()
        .then(stats => {
          commit('setStats', stats);
          return stats;
        })
    },
    getFinishedBackup({commit}) {
      return getFinishedBackup()
        .then(finishedBackup => {
          commit('setFinishedBackup', finishedBackup);
          return finishedBackup;
        })
    },
  },

  mutations: {
    setStats(state, stats) {
      state.stats = stats
    },
    setFinishedBackup(state, finishedBackup) {
      state.finishedBackup = finishedBackup
    },
  },

  getters: {
    getTotalProtectedData: (state) => () => {
      const
        driveStats = state.stats?.driveStats?.totalData || 0,
        exchangeStats = state.stats?.exchangeStats?.totalData || 0,
        siteStats = state.stats?.siteStats?.totalData || 0,
        teamStats = state.stats?.teamStats?.totalData || 0,

        totalData = driveStats + exchangeStats + siteStats + teamStats

      return totalData
    },
  },

  state: defaultState(),

  modules: {},

}
