import {GET, POST} from "@/services/gateway";

const listUsers = ({pagination, search, filter}) => {
  return POST('seat/user/list', {pagination, search, filter});
}

const getUserDetails = (id) => {
  return GET('exchange/user', {id})
}

const listSnapshots = ({userId, date}) => {
  return POST('exchange/user/snapshots', {userId, date})
}

const createBackup = (userId) => {
  return POST('exchange/createbackup', {userId})
}

export {
  listUsers,
  createBackup,
  getUserDetails,
  listSnapshots,
}
