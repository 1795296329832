<template>
  <div class="i-radio-buttons" :class="vertical">
    <div class="field-label" v-if="label">
      {{ label }}
    </div>

    <div class="radio-options" v-for="option in options" :key="option.value">
      <label :class="{'disabled-label' : option.disabled}">
        <input
          type="radio"
          :name="name"
          :checked="option.value === value"
          :value="option.value"
          :disabled="option.disabled"
          @input="onInput"
        />
        {{ option.label }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "IRadioButtons",
  props: {
    label: {
      type: String
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean
    },
    vertical: {
      type: String, // horizontal|vertical
    }
  },

  data() {
    return {}
  },

  methods: {
    onInput(e) {
      this.$emit('input', e.target.value);
    },
  },

  created() {
    // this.$emit('input', this.value);
  }
}
</script>

<style lang="scss">
@import "IRadioButtons";
</style>
