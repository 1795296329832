import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import './boot/fontawesome';
import './boot/common';
import './boot/filters';
import './boot/vuelidate';
import './boot/notifications';
import {getEllipsisStr} from "@/services/tableDataFormater";

Vue.config.productionTip = false


Vue.prototype.$hasListener = function $hasListener(listenerName) {
    let component = this;
    while (component) {
        if (component.$listeners[listenerName]) {
            return true;
        }
        component = component.$parent;
    }

    return false;
};


Vue.filter('iEmptyData', function (data, isFolder = false, emptyData = 'NA') {
    try {
        if (((Array.isArray(data) && !data.length) || data === null || data === 'null' || data === undefined || data === "undefined" || (!data.trim())) && isFolder) {
            return ''
        } else if (((Array.isArray(data) && !data.length) || data === null || data === 'null' || data === undefined || data === "undefined" || (!data.trim()))) {
            return emptyData
        }
        return data
    } catch (e) {
        return data
    }
});

Vue.filter('iEmptyDate', function (data, emptyData = 'NA') {

    try {
        if ((Array.isArray(data) && !data.length) || (!data.trim()) || data === null || data === 'null' || data === undefined || data === "undefined" || data <= '01-01-2020') {
            return emptyData
        }
        return data
    } catch (e) {
        return data
    }
});

Vue.filter('iCapitalizeString', function (str) {
    try {
        return str[0].toUpperCase() + str.slice(1).toLowerCase()
    } catch (e) {
        return str
    }

});

Vue.filter('iEllipsis', function (str) {
    try {
        if (str) {
            return getEllipsisStr(str)
        }
    } catch (e) {
        return str
    }

});


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
