import exchange from "@/store/office/recovery/exchange/exchange";
import sharePoint from "@/store/office/recovery/share-point/sharePoint";
import oneDrive from "@/store/office/recovery/one-drive/oneDrive"
import team from "@/store/office/recovery/team/team";
import user from "@/store/office/recovery/user";

export default {
  namespaced: true,

  actions: {},

  modules: {
    exchange,
    oneDrive,
    sharePoint,
    team,
    user
  }
}
