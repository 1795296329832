import {GET, POST} from "@/services/gateway";

const listTeams = ({pagination, search, filter}) => {
  return POST('seat/team/list', {pagination, search, filter});
}

const listSnapshots = ({teamId, date}) => {
  return POST('recovery/team/snapshot/list', {teamId, date})
}

const createBackup = (teamId) => {
  return GET('recovery/team/backup', {teamId})
}

const getTeamDetails = (teamId) => {
  return GET('recovery/team/team', {teamId})
}



export {
  listTeams,
  getTeamDetails,
  createBackup,
  listSnapshots
}
