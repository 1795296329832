import {defaultPagination} from "@/services/pagination";
import {
    listBreadcrumps,
    listDrive,
    exportSnapshot,
    restoreSnapshot
} from "@/services/api/office/recovery/share-point/data-set/drive";

const defaultState = () => ({
    files: [],
    searchFiles: [],
    searchPagination: {
      ...defaultPagination(),
      sortBy: "snapshots.drive_item.name",
      sortDescending: false
    },
    pagination: {
      ...defaultPagination(),
      sortBy: "snapshots.drive_item.name",
      sortDescending: false
    },
    folderId: null,
    breadcrumps: null,
    search: null,
    initialSearch:null
})

export default {
    namespaced: true,

    actions: {
        listFiles({commit, state}, data) {
            return listDrive({
                ...data,
                pagination: state.pagination,
                folderId: state.folderId,
                searchTerm: state?.search?.searchTerm
            })
                .then(({result, pagination}) => {
                    let index = Date.parse(new Date());
                    const res = result.map(file => {
                        return {
                            ...file,
                            originalId: file.id,
                            id: index++
                        }
                    })

                    commit('setFiles', res)
                    commit('setPagination', pagination)
                })
        },
        searchFiles({commit, state}, data) {
            return listDrive(data)
                .then(({result, pagination}) => {
                    commit('setSearchFiles', result)
                    commit('setSearchPagination', pagination)
                    return {result, pagination}
                })
        },
        searchSnapshotDetails({commit, state}, data) {
            return listDrive(data)
                .then(({result, pagination}) => {
                    commit('setSearchFiles', result)
                    commit('setSearchPagination', pagination)
                    return {result, pagination}
                })
        },
        listBreadcrumps({state, commit}, {siteId, snapshotDate}) {
            if (state.folderId && siteId && snapshotDate) {
                return listBreadcrumps({
                    siteId,
                    snapshotDate,
                    folderId: state.folderId
                })
                    .then(result => {
                        commit('setBreadcrumps', result);
                    })
            } else {
                commit('setBreadcrumps', []);
                return Promise.resolve();
            }
        },
        exportSnapshot({commit}, data) {
            return exportSnapshot(data);
        },
        restoreSnapshot({commit}, data) {
            return restoreSnapshot(data);
        }
    },

    mutations: {
        setFiles(state, files) {
            state.files = files;
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setSearchFiles(state, files) {
            state.searchFiles = files;
        },
        setSearchPagination(state, pagination) {
            state.searchPagination = pagination;
        },
        resetSearchPagination(state) {
            state.searchPagination = {
                ...defaultPagination(),
                sortBy: "snapshots.drive_item.name",
                sortDescending: false
            };
        },
        setFolderId(state, folderId) {
            state.folderId = folderId;
        },
        setSearch(state, search) {
            state.search = search;
        },
        setBreadcrumps(state, breadcrumps) {
            state.breadcrumps = breadcrumps;
        },
        setInitialSearch(state, initialSearch){
          state.initialSearch = initialSearch
        },
        resetState(state) {
            Object.assign(state, defaultState())
        }
    },

    state: defaultState()
}
