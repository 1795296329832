<template>
  <div class="i-textarea">
    <div v-if="label" class="field-label">
      {{ label }}
    </div>

    <div class="input-container">
      <textarea
        :disabled="disabled"
        :value="value"
        :name="name"
        :placeholder="placeholder"
        @input="onInput($event.target.value)"
      />

    </div>

  </div>
</template>

<script>
export default {
  name: "ITextarea",
  props: {
    value: {
      type: String,
    },
    name: {
      type: String,
    },
    fieldType: {
      type: String,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },

  methods: {
    onInput(value) {
      this.$emit('input', value.trim())
    },
  }
}
</script>

<style lang="scss">
@import "ITextarea";
</style>
