<template>
  <div class="i-site-multi-picker">

    <i-multi-picker
        label-counter="site(s)"
        :label="label"
        :value="value"
        :fetch-options="fetchSites"
        option-label="name"
        option-value="siteId"
        @input="$val => $emit('input',$val)"
    />
  </div>
</template>

<script>
import {listSites} from "@/services/api/office/recovery/share-point/sharePoint";
import IMultiPicker from "@/components/forms/IMultiPicker.vue";

export default {
  name: 'ISiteMultiPicker',
  components: {IMultiPicker},
  props: {
    value: {},
    label: {
      type: String,
      default: "Sites"
    },
    placeholder: {
      type: String,
      default: 'Search all sites'
    },
  },

  data() {
    return {
      selected: null,
      sites: []
    }
  },

  methods: {
    fetchSites({search}) {

      return listSites({
        pagination: {
          pageNumber: 1,
          pageSize: 30,
          sortBy: 'name',
          sortDescending: false
        },
        search,
        filter: {
          "statuses": [
            1, 2, 4
          ]
        }
      })
          .then(({result}) => {
            return result
          })
    },
  },


}
</script>

<style lang="scss">
@import "IUserMultiPicker.scss";
</style>
