import {
    createBackup,
    getUserDetails,
    listSnapshots
} from "@/services/api/office/recovery/exchange/exchange";
import mail from "@/store/office/recovery/exchange/data-set/mail"
import contact from "@/store/office/recovery/exchange/data-set/contact"
import calendar from "@/store/office/recovery/exchange/data-set/calendar"
import exports from "@/store/office/recovery/exchange/exports";
import restores from "@/store/office/recovery/exchange/restores";
import backups from "@/store/office/recovery/exchange/backups";

const defaultState = () => ({
    userMap: {},
});

export default {
    namespaced: true,
    actions: {
        getUserDetails({commit}, userId) {
            return getUserDetails(userId)
                .then(user => {
                    commit('setUser', user);
                })
        },
        createBackup({commit}, userId) {
            return createBackup(userId);
        },
        listSnapshots({commit}, {userId, date}) {
            return listSnapshots({userId, date})
        },
    },

    mutations: {
        setUser(state, user) {
            state.userMap = {
                ...state.userMap,
                [user.userId]: user
            };
        },
    },

    getters: {
        getUserById: (state) => (id) => {
            return state.userMap[id];
        },
    },

    state: defaultState(),

    modules: {
        mail,
        contact,
        calendar,
        exports,
        restores,
        backups
    },

}
