let notifyMixin = {
    methods: {
        $notifySuccess: function (notificationMessage = 'Your data was successfully updated') {
            this.$notify({
                group: 'foo',
                type: 'success-notification toast-notification',
                text: `<img src="${require('@/assets/icons-svg/tick.svg')}" class="notify-icon"> ${notificationMessage}`,
                closeOnClick: true,
            })
        },
        $notifyError: function (notificationMessage = 'Something went wrong..Please try again later') {
            this.$notify({
                group: 'foo',
                type: 'error-notification toast-notification',
                text: `<img src="${require('@/assets/icons-svg/failuer-icon.svg')}" class="notify-icon"> ${notificationMessage}`,
                closeOnClick: true,
            })
        },
    },
}


export default notifyMixin;
