import drive from "@/store/google/recovery/drive/drive";
import gmail from "@/store/google/recovery/gmail/gmail";
import calendar from "@/store/google/recovery/calendar/calendar";
import user from "@/store/google/recovery/user";
import contact from "@/store/google/recovery/contact/contact";


export default {
  namespaced: true,

  actions: {},

  modules: {
    user,
    drive,
    gmail,
    calendar,
    contact,
  }
}
