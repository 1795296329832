const generateMicrosoftAuthLink = (id) => {
  let URL = 'https://app-staging.idrivec2c.com/api';

  if (process.env.VUE_APP_API_URL) {
    URL = process.env.VUE_APP_API_URL;
  }

;
  let MICROSOFT_CLIENT_ID = 'ba20ccda-017b-47f5-ba53-6b7e8e2eb876';

  if (process.env.VUE_APP_MICROSOFT_CLIENT_ID) {
    MICROSOFT_CLIENT_ID = process.env.VUE_APP_MICROSOFT_CLIENT_ID;
  }

  return `https://login.microsoftonline.com/common/adminconsent?client_id=${MICROSOFT_CLIENT_ID}&state=${id}&redirect_uri=${URL}/Tenant/MicrosoftCallback`
}

export {
  generateMicrosoftAuthLink
}
