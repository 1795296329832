import recovery from "@/store/google/recovery/recovery";
import gUsers from './google/seat-management/user'
import stats from "@/store/google/stats";
import report from "@/store/google/report";
import userReport from "@/store/google/userReport";
import backups from "@/store/google/backups";
import backupSettings from "@/store/google/seat-management/backupsettings";

const defaultState = () => ({})

export default {
  namespaced: true,

  actions: {},

  modules: {
    recovery: recovery,
    gUsers: gUsers,
    stats: stats,
    report,
    userReport,
    backups,
    backupSettings,
  }
}
