import {POST} from "@/services/gateway";

const listDrive = ({siteId, folderId, snapshotDateTime, pagination, searchTerm, siteIds}) => {
  return POST('recovery/sites/drive/search', {siteId, folderId, snapshotDateTime, pagination, searchTerm, siteIds})
}

const listBreadcrumps = ({siteId, folderId, snapshotDate}) => {
  return POST('recovery/sites/drive/breadcrumbs', {siteId, folderId, snapshotDate})
}

const exportSnapshot = ({snapshotDateTime, sourceIds, searchTerm, selectedIds}) => {
  return POST('recovery/sites/drive/export', {snapshotDateTime, sourceIds, searchTerm, selectedIds})
}

const restoreSnapshot = ({snapshotDateTime, sourceIds, searchTerm, destinationId, snapshotId, selectedIds}) => {
  return POST('recovery/sites/drive/restore', {snapshotDateTime, sourceIds, searchTerm, destinationId, snapshotId, selectedIds})
}

export {
  listDrive,
  listBreadcrumps,
  exportSnapshot,
  restoreSnapshot
}
