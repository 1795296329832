const axios = require('axios');
import Store from "@/store";
import router from "@/router";

let URL = '/api';

if (process.env.VUE_APP_API_URL) {
  URL = process.env.VUE_APP_API_URL;
}

const client = axios.create();

client.interceptors.response.use(undefined, (err) => {
  const error = err.response;

  if (error.status === 401) {
    Store.dispatch('auth/signOut')
      .then(() => {
        router.push({name: 'SignInPage'})
      })
  }
  return Promise.reject(error?.data?.result?.errors || error.message);

})

const getApiBaseUrl = (url, self) => {
  if (self?.apiUrl) {
    return `${self.apiUrl}/api`;
  }
  return url;
}

const GET = (url, data) => {
  return client.get(`${getApiBaseUrl(URL, Store?.state?.auth?.self)}/${url}`, {
    params: data,
    headers: {
      Authorization: `Bearer ${Store.state.auth.accessToken}`
    },
  })
    .then(result => Promise.resolve(result.data.result))
}

const POST = (url, data) => {
  return client.post(`${getApiBaseUrl(URL, Store?.state?.auth?.self)}/${url}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${Store.state.auth.accessToken}`
      }
    }
  )
    .then(result => Promise.resolve(result.data.result))
}

const DELETE = (url, id) => {
  return client.delete(`${getApiBaseUrl(URL, Store?.state?.auth?.self)}/${url}/${id}`,
    {
      headers: {
        Authorization: `Bearer ${Store.state.auth.accessToken}`
      }
    }
  )
    .then(result => Promise.resolve(result.data.result))
}


export {
  POST,
  GET,
  DELETE
}
