import {
    listUsers,
} from "@/services/api/office/recovery/one-drive/oneDrive";
import {defaultPagination} from "@/services/pagination";

const defaultState = () => ({
    users: [],
    pagination: {
        ...defaultPagination(),
        sortBy: 'name',
        sortDescending: false
    },
    search: null,
});

export default {
    namespaced: true,
    actions: {
        listUsers({commit, state},) {
            return listUsers({
                pagination: state.pagination,
                search: state.search,
                filter: {
                    "statuses": [
                        1, 2, 4
                    ]
                }
            })
                .then(({result, pagination}) => {
                    commit('setUsers', result)
                    commit('setPagination', pagination)
                })
        },
    },

    mutations: {
        setUsers(state, users) {
            state.users = users;
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        resetPagination(state, {keepSort}) {
            if (keepSort) {
                state.pagination = {
                    ...defaultPagination(),
                    sortBy: state.pagination.sortBy,
                    sortDescending: state.pagination.sortDescending,
                }
            } else {
                state.pagination = {
                    ...defaultPagination(),
                    sortBy: 'name',
                    sortDescending: false
                };
            }

        },
        setSearch(state, search) {
            state.search = search;
        },
        setUser(state, user) {
            state.userMap = {
                ...state.userMap,
                [user.userId]: user
            };
        },
        resetState(state) {
            Object.assign(state, defaultState())
        }
    },

    getters: {
        getUserById: (state) => (id) => {
            return state.userMap[id];
        },
    },

    state: defaultState(),
}
