import {updateAutoAdd, list} from "@/services/api/google/seat-management/backupsettings";

const defaultState = () => ({
  autoAdd: {
    users: false,
  }
});

export default {
  namespaced: true,

  actions: {
    list({commit}) {
      return list()
        .then(({autoAdd}) => {
          commit('setAutoAdd', autoAdd)
        })
    },
    updateAutoAdd({commit}, data) {
      return updateAutoAdd(data)
        .then(({autoAdd}) => {
          commit('setAutoAdd', autoAdd)
        })
    }
  },

  mutations: {
    setAutoAdd(state, autoAdd) {
      state.autoAdd = autoAdd
    }
  },


  getters: {
    getAutoAddSettingByType: (state) => (seatType) => {
      return state.autoAdd && state.autoAdd[seatType];
    },
  },

  state: defaultState()
}
