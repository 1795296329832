<template>
  <div class="i-demo-picker i-select">

    <i-select
      :readonly="readonly"
      :label="label"
      :value="value"
      :options="users"
      option-label="label"
      option-value="guid"
      @input="onInput"
      :emit-value="emitValue"
      :fetch-options="reloadUsers"
      :fetch-more-options="loadMoreUsers"
      :enable-infinite-scroll="true"
      :sort="false"
      :clearable="true"
      :multiple="multiple"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import ISelect from "@/components/common/ISelect";

export default {
  name: "IDemoPicker",
  components: {ISelect},
  props: {
    value: {
      type: Object | String
    },
    label: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    defaultValue: { // workaround to set default value for "self"
      type: [String, Object]
    },
    emitValue: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },


  data: () => ({
    users: [],
  }),


  computed: {
    ...mapState({
      self: state => state.auth.self
    }),
  },

  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
    loadMoreUsers({value, label}) {
      return Promise.resolve().then((value, label) => {
        this.users = [
          {
            label: 'Volvo',
            value: 'volvo'
          },
          {
            label: 'Fiat',
            value: 'fiat'
          },
        ]
      })
    },
    reloadUsers({value, label}) {
      return Promise.resolve().then((value, label) => {
        this.users = [
          {
            label: 'Volvo',
            value: 'volvo'
          },
          {
            label: 'Ford',
            value: 'ford'
          },
          {
            label: 'Fiat',
            value: 'fiat'
          },
          {
            label: 'Mazda',
            value: 'mazda'
          },
        ]
      })
    }
  },

  created() {
    if (this.defaultValue === 'self' && !this.value) {

      if (this.emitValue) {
        this.$emit('input', this.self.id)

      } else {
        this.$emit('input', this.self)
      }
    }
  }

}
</script>

<style scoped>

</style>
