<template>
  <div class="i-select">
    <div class="field-label" v-if="label">
      {{ label }}
    </div>

    <v-select
      ref="select"
      :options="optionsSorted"
      :label="optionLabel"
      :value="value"
      :disabled="readonly"
      @input="onInput"
      @open="onOpen"
      @close="onClose"
      :reduce="reduce"
      :filterable="fetchOptions ? false: searchable"
      :searchable="searchable"
      :clearable="clearable"
      :clear-search-on-blur="() => false"
      :multiple="multiple"
      :append-to-body="true"
      @search="onSearch"
    >

      <template #list-footer v-if="enableInfiniteScroll">
        <li v-show="hasNextPage && !isSearching" ref="load" class="loader">
          test
          <!--          <q-spinner-->
          <!--            color="primary"-->
          <!--            size="2em"-->
          <!--            :thickness="2"-->
          <!--          />-->
        </li>
      </template>

    </v-select>
  </div>
</template>


<script>
import 'vue-select/dist/vue-select.css';

import vSelect from 'vue-select'

export default {
  name: 'ISelect',
  components: {vSelect},
  props: {
    label: {
      type: String,
    },
    value: {
      type: Array | String,
    },
    options: {
      type: Array,
      required: true
    },
    clearable: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false
    },
    optionValue: {
      type: String,
      default: "value",
      required: true
    },
    optionLabel: {
      type: String,
      default: "label",
      required: true
    },
    emitValue: {
      type: Boolean,
      default: true //only for nto multiple
    },
    sort: {
      type: Boolean,
      default: true
    },
    fetchOptions: {
      type: Function,
    },
    fetchMoreOptions: {
      type: Function,
    },
    enableInfiniteScroll: {
      type: Boolean,
      default: false
    },
  },

  data: () => ({
    observer: null,
    moreOptions: [],
    search: null,
    timeoutInterval: null,
    isSearching: false,
    opened: false
  }),

  computed: {
    optionsSorted() {
      return this.options;
    },
    hasNextPage() {
      // return this.enableInfiniteScroll && this.pagination.rowsNumber > this.options.length
    }
  },
  methods: {
    reduce(val) {
      if (!this.emitValue || !val || this.multiple) {
        return val;
      }
      return val[this.optionValue || 'value']
    },
    onInput(val) {
      this.$emit('input', val);
    },
    onSearch(search, loading) {
      if (!this.$refs.select.open) {
        return false;
      }
      this.search = search;
      if (!this.fetchOptions) {
        return false
      }
      loading(true);
      this.searchOptions(loading, search, this);
    },

    searchOptions: (async (loading, search, vm) => {
      vm.isSearching = true;
      await vm.fetchOptions({
        search, pagination: {
          ...vm.pagination,
          page: 1
        }
      })
        .then((options) => loading(false))
        .finally(() => vm.isSearching = false)

      vm.observer.observe(vm.$refs.load)

    }),

    infiniteScroll: (async ([{isIntersecting, target}], vm) => {
      if (isIntersecting && vm.fetchMoreOptions) {

        await vm.fetchMoreOptions({
          search: vm.search, pagination: {
            ...vm.pagination,
            page: vm.pagination.page + 1
          }
        })

        await vm.$nextTick();
        const ul = target.offsetParent;
        if (ul) {
          ul.scrollTop = ul.scrollHeight / 2

        }
      }
    }),

    async onOpen() {

      if (this.fetchOptions) {
        await this.fetchOptions({search: this.search, pagination: {...this.pagination, page: 1}})

        if (this.hasNextPage) {
          await this.$nextTick();
          this.observer.observe(this.$refs.load)
        }
      }
    },
    onClose() {
      if (this.observer) {
        this.observer.disconnect()
      }
    },

  },

  mounted() {
    if (this.enableInfiniteScroll) {
      this.observer = new IntersectionObserver($ev => this.infiniteScroll($ev, this))
    }
  },

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }
}
</script>

<style lang="scss">
@import "ISelect";
</style>
