<template>
  <div class="i-checkbox">
    <div v-if="label" class="field-label">{{ label }}</div>

    <div class="checkbox-container" :class="{'vertical' : !vertical}">
      <label v-for="option in options" :key="option.value">
        <input
          type="checkbox"
          :checked="value && value.includes(option.value)"
          :name="name"
          :value="option.value"
          @input="onInput"
        />
        {{ option.label }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "ICheckbox",
  props: {
    label: {
      type: String
    },
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      default: () => {
        return []
      }
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {}
  },

  methods: {
    onInput(e) {
      this.$emit('input', parseInt(e.target.value));
    },
  },
}
</script>

<style lang="scss">
@import "ICheckbox";
</style>
