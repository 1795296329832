import {
    getUserDetails,
    listSnapshots,
    createBackup,
    exportSnapshot,
    restoreSnapshot
} from "@/services/api/google/recovery/gmail/gmail";
import files from "@/store/google/recovery/gmail/data-set/file";
import exports from "@/store/google/recovery/gmail/exports";
import restores from "@/store/google/recovery/gmail/restores";
import backups from "@/store/google/recovery/gmail/backups";

const defaultState = () => ({
    userMap: {},
});

export default {
    namespaced: true,
    actions: {
        getUserDetails({commit}, userId) {
            return getUserDetails(userId)
                .then(user => {
                    commit('setUser', user);
                })
        },
        createBackup({commit}, userId) {
            return createBackup(userId);
        },
        listSnapshots({commit}, {userId, date}) {
            return listSnapshots({userId, date})
        },
        exportSnapshot({commit}, data) {
            return exportSnapshot(data);
        },
        restoreSnapshot({}, data) {
            return restoreSnapshot(data);
        },
    },

    mutations: {
        setUser(state, user) {
            state.userMap = {
                ...state.userMap,
                [user.userId]: user
            };
        },
    },

    getters: {
        getUserById: (state) => (id) => {
            return state.userMap[id];
        },
    },

    state: defaultState(),

    modules: {
        files,
        exports,
        restores,
        backups,
    }
}
